import { Component, Inject, OnInit, Pipe, PipeTransform } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServiceVersion } from '@core/models';
import {
  AdminApiService, AuthApiService, DocumentApiService, StorageApiService
} from '@core/services';
import { environment } from '@env';
import { from } from 'rxjs';

export class AboutDialogData {
  constructor(
    public isLoggedIn: boolean,
    public name: string,
    public buildId: string,
    public buildNumber: string,
    public pipeline: string,
    public branch: string,
    public stage: string,
    public repository: string,
    public commit: string,
    public environmentId: string,
    public environmentName: string) { }
}

class Utility {
  static getBranchName(path: string) : string {
    return path.startsWith('refs/heads/') ? path.substring(11) : path;
  }
}

@Pipe({name: 'branchName'})
export class BranchNamePipe implements PipeTransform {
  transform(path: string): string {
    return Utility.getBranchName(path);
  }
}

@Pipe({name: 'leftString'})
export class LeftStringPipe implements PipeTransform {
  transform(value: string, length: number = 8): string {
      return value.substring(0, length);
  }
}

@Component({
  selector: 'app-about-dialog',
  templateUrl: './about-dialog.component.html',
  styleUrls: ['./about-dialog.component.scss']
})
export class AboutDialogComponent implements OnInit {
  private readonly gitUrl
    = 'https://dev.azure.com/bsi-online/Commercial%20Solutions/_git/';
  private readonly envUrl
    = 'https://dev.azure.com/bsi-online/Commercial%20Solutions/_environments/';
  private readonly buildUrl
    = 'https://dev.azure.com/bsi-online/Commercial%20Solutions/_build/'

  adminApiVersion?: ServiceVersion;
  authApiVersion?: ServiceVersion;
  documentApiVersion?: ServiceVersion;
  storageApiVersion?: ServiceVersion;

  isProduction = environment.production;

  constructor(
    public adminService: AdminApiService,
    public authService: AuthApiService,
    public documentService: DocumentApiService,
    public storageService: StorageApiService,
    public dialogRef: MatDialogRef<AboutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AboutDialogData) { }

  ngOnInit(): void {
    if (this.data.isLoggedIn) {
      from(this.adminService.getVersion()).subscribe(v => this.adminApiVersion = v);
      from(this.authService.getVersion()).subscribe(v => this.authApiVersion = v);
      this.documentService.getVersion().subscribe(v => this.documentApiVersion = v);
      from(this.storageService.getVersion()).subscribe(v => this.storageApiVersion = v);
    }
  }

  openRepo(repo: string) {
    window.open(`${this.gitUrl}${repo}`);
  }

  openBranch(repo: string, branch: string) {
    var msg = `${this.gitUrl}${repo}?version=GB${Utility.getBranchName(branch).replace('/','%2F')}`;
    window.open(msg);
  }

  openCommit(repo: string, commit: string) {
    window.open(`${this.gitUrl}${repo}/commit/${commit}`);
  }

  openEnvironment(environmentId: string) {
    window.open(`${this.envUrl}${environmentId}?view=resources`);
  }

  openBuild(buildId: string) {
    window.open(`${this.buildUrl}results?buildId=${buildId}&view=results`);
  }
}
