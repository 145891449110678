<mat-toolbar color="primary" class="appbar mat-elevation-z4" (click)="onCloseAccount()">
  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon class="material-symbols-outlined">menu</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item [routerLink]="['']">
      <mat-icon class="material-symbols-outlined">home</mat-icon>
      <span>Home</span>
    </button>
    @if (canViewMembers()) {
      <button mat-menu-item [routerLink]="['members']">
        <mat-icon class="material-symbols-outlined">group</mat-icon>
        <span>Members</span>
      </button>
    }
    @if (canViewWorkspaces()) {
      <button mat-menu-item (click)="onNavigateToWorkspaces()">
        <mat-icon class="material-symbols-outlined">workspaces</mat-icon>
        <span>Workspaces</span>
      </button>
    }
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="onAbout()">
      <mat-icon class="material-symbols-outlined">info</mat-icon>
      <span>About</span>
    </button>
  </mat-menu>
  <img class="logo" src="/assets/bsi-logo.svg" alt="logo">
  <span>{{ title }}</span>

  @if (authenticated()) {
    <div class="spacer"></div>
    <button mat-icon-button (click)="onToggleAccount($event)">
      <mat-icon class="material-symbols-outlined">account_circle</mat-icon>
    </button>
  }
</mat-toolbar>

@if (authenticated() && showAccount()) {
  <mat-card class="account mat-elevation-z6">
    <mat-card-content>
      <p class="name">{{ name() }}</p>
      <p class="email">{{ email() }}</p>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button color="primary" (click)="onSwitchAccount()">Switch Account</button>
    </mat-card-actions>
  </mat-card>
}

<main (click)="onCloseAccount()">
  <router-outlet></router-outlet>
</main>
