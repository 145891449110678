import { Component, computed, OnInit, signal } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthService, DialogService, StorageService } from '@core/services';
import { IdentityService } from '@core/services/identity.service';
import { MonacoEditorService } from '@core/services/monaco-editor.service';
import { environment } from '@env';
import { AboutDialogComponent, AboutDialogData } from './about-dialog/about-dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  readonly title = 'Connect Platform Portal';

  readonly authenticated = computed(() => this.identityService.isAuthenticated());
  readonly name = computed(() => this.identityService.claims().name);
  readonly email = computed(() => this.identityService.claims().email);
  readonly showAccount = signal(false);
  readonly canViewMembers = computed(() => this.identityService.isSuperAdmin());
  readonly canViewWorkspaces = computed(() =>
    this.identityService.isSuperAdmin()
    || this.identityService.isAdmin());

  constructor(
    private authService: AuthService,
    private identityService: IdentityService,
    private monacoEditorService: MonacoEditorService,
    private msalService: MsalService,
    private dialogService: DialogService,
    private readonly router: Router,
    private storageService: StorageService) {
  }

  ngOnInit() {
    this.monacoEditorService.load();
  }

  onNavigateToWorkspaces() {
    this.storageService.set('viewingBin', false);
    this.router.navigate(['/workspaces']);
  }

  onSwitchAccount() { // Add log out function here
    this.authService.logout();
    this.msalService.logoutRedirect({
      postLogoutRedirectUri: '/',
    });
  }

  onToggleAccount(e: Event) {
    e.stopPropagation();
    if (this.authService.isAuthenticated()) {
      this.showAccount.update(show => !show);
    } else {
      this.login();
    }
  }

  onCloseAccount() {
    this.showAccount.set(false);
  }

  onAbout() {
    this.dialogService.show$(AboutDialogComponent, {
      width: '650px',
      data: new AboutDialogData(
        this.authenticated(), 'Portal',
        environment.buildId, environment.buildNumber,
        environment.pipeline, environment.branch,
        environment.stage, environment.repository, environment.commit,
        environment.environmentId, environment.environmentName)
    });
  }

  private login(): void {
    this.msalService.loginRedirect();
  }
}
