import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from '@modules/auth.module';
import { MaterialModule } from '@modules/material.module';

import {
  AboutDialogComponent, BranchNamePipe, LeftStringPipe
} from './about-dialog/about-dialog.component';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';

import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { CanDeactivateGuard } from './can-deactivate.guard';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgOptimizedImage } from '@angular/common';
import { environment } from '@env';
import { AuthInterceptor } from '@core/helpers/auth.interceptor';

@NgModule({ declarations: [
        AboutDialogComponent,
        AppComponent,
        BranchNamePipe,
        HomeComponent,
        LeftStringPipe,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent], imports: [AppRoutingModule,
        AuthModule,
        BrowserAnimationsModule,
        BrowserModule,
        CoreModule,
        MaterialModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            registrationStrategy: 'registerWhenStable:30000'
        }),
        SharedModule,
        NgOptimizedImage], providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        CanDeactivateGuard,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
