import { Component, computed, effect, OnInit, signal } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, IdentityService, StorageService } from '@core/services';

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { filter } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  readonly isAuthenticated = computed(() => this.identityService.isAuthenticated());
  readonly isAuthenticating = signal(true);
  readonly isSuperAdmin = computed(() => this.identityService.isSuperAdmin());
  readonly canViewMembers = computed(() => this.identityService.isSuperAdmin());
  readonly canViewWorkspaces = computed(() =>
    this.identityService.isSuperAdmin() || this.identityService.isAdmin());

  constructor(
    private broadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private authService: AuthService,
    private identityService: IdentityService,
    private readonly router: Router,
    private storageService: StorageService) {
      effect(() => {
        if (this.isAuthenticated()) {
          this.isAuthenticating.set(false);
        }
      }, { allowSignalWrites: true });
    }

  ngOnInit(): void {
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        if (!this.identityService.isAuthenticated()) {
          this.authService.login();
        }
      });
  }

  onLogin(): void {
    this.msalService.loginRedirect();
  }

  onNavigateToWorkspaces(): void {
    this.storageService.set('viewingBin', false);
    this.router.navigate(['/workspaces']);
  }
}
